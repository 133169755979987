<template>
  <div style="overflow-x: hidden">
    <vs-row
      vs-justify="start"
      :style="{
        backgroundColor: cor1,
        background: bgImage,
        backgroundSize: 'cover'
      }"
    >
      <vs-col
        type="flex"
        vs-justify="start"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
        class="px-5 pt-5"
        style="min-height: calc(100vh - 186px) ;"
      >
        <div>
          <p
            :style="{ fontFamily: welcomeFont }"
            class="call text-white mt-5 py-5 ml-5"
          >
            Olá, {{ user }} <br>

            {{ msg1 }} <br>

            {{ client }}. <br>

            <span class="subCall">
              Powered by Fanbase
            </span>
          </p>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
// Config
import themeConfig from '@/../themeConfig.js';

export default {
  name: 'HomeDash',
  beforeRouteEnter(_to, _from, next) {
    next(
      (vm) => vm.projId = vm.$route.query.prj,
      (err) => alert(err),
    );
  },
  data: () => ({
    cor1: themeConfig.themeColor,
    loginData: {},
    msg1: '',
    client: '',
    user: '',
    welcomeFont: '',
  }),
  computed: {
    bgImage() {
      return this.$store.state.configBox.bgInterval;
    },
  },
  watch: {
    '$store.state.configBox'() {
      this.configHome();
    },
  },
  created() {
    this.projId = 0;
    this.projId = this.$store.state.selectedProject;
    this.configHome();
  },
  methods: {
    configHome() {
      let welcome = '';
      this.loginData = this.$store.state.loginData;

      if (this.loginData.identity.gender === 'F') {
        welcome = this.$store.state.configBox.welcomeMsgF.split('\n');
      } else {
        welcome = this.$store.state.configBox.welcomeMsgM.split('\n');
      }

      this.msg1 = welcome[0];
      this.client = welcome[1];
      this.user = this.loginData.identity.name1;

      this.cor1 = this.$store.state.configBox.cor1;
      this.welcomeFont = this.$store.state.configBox.fontFamilyWelcomeMsg;
    },
  },
};
</script>

<style>
.ps {
  height: 100px;
}

.call {
  font-weight: 600;
  font-size: 2.4rem;
}

.subCall {
  font-weight: 300;
  font-size: 1.5rem;
}
</style>
